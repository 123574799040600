<template>
    <div class="main">
        <van-loading color="#1989fa" size="48px"></van-loading>
    </div>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex'
import { getVisitorById, visitorModify } from '@/api/visitor-api'
import { getEmployeeById, employeeUpdate } from '@/api/employee-api'

import { getVisitorSettings } from '@/api/setting-api'
import { visitor_info_mixin_table } from '@/utils/visitor-info-mixin'

export default {
    mixins: [visitor_info_mixin_table],

    mounted() {
        if (this.loginState) {
            this.goToHome();
            return;
        }

        let type = localStorage.getItem("type");
	    let token = localStorage.getItem("token");

        if ((type == null)||(token == null)) {
            this.goToHome();
            return
        }

        if (type === this.ACCOUNT_TYPE_VISITOR) {
            (async () => {
                let visitor = {
                    "token": token
                };

                this.setAccountType(this.ACCOUNT_TYPE_VISITOR);
                this.setVisitor(visitor);
                
                let visitorInfo = (await getVisitorById()).data;

                this.printDebug("sync visitorInfo => ", visitorInfo)
                
                if ((visitorInfo === null) || (visitorInfo.code !== 200) || (visitorInfo.data === null)
                    || (visitorInfo.data.state !== "VALID") || (visitorInfo.data.type === "black")) {
                    this.setLoginFlag(false)
                    this.setAccountType(null)
                    this.setVisitor(null)

                    localStorage.removeItem("type")
                    localStorage.removeItem("token")
                } else {
                    visitorInfo.data.token = this.visitor.token;
                    this.setLoginFlag(true)
                    this.setVisitor(visitorInfo.data);
                    this.checkOpenIdAndUpdate(this.ACCOUNT_TYPE_VISITOR, this.visitor)
                    
                    //检查访客信息是否需要完善
                    let setting = await getVisitorSettings();
                    let result = this.verifyVisitorInfo(visitorInfo.data, setting);

                    if (!result) {
                        this.$toast.success("请完善信息")
                        this.goToAccountDetail();
                        return
                    }
                }
                
                this.goToHome();
            })();
        } else if (type === this.ACCOUNT_TYPE_EMPLOYEE) {
            (async () => {
                let employee = {
                    "token": token
                };

                this.setAccountType(this.ACCOUNT_TYPE_EMPLOYEE)
                this.setEmployee(employee)
                
                let employeeInfo = (await getEmployeeById()).data;

                this.printDebug("sync employeeInfo => ", employeeInfo)
                
                if ((employeeInfo === null) || (employeeInfo.code !== 200) || 
                    (employeeInfo.data === null) || (employeeInfo.data.state !== "VALID")) {
                    this.setLoginFlag(false)
                    this.setAccountType(null)
                    this.setEmployee(null);

                    localStorage.removeItem("type")
                    localStorage.removeItem("token")
                } else {
                    employeeInfo.data.token = this.employee.token;
                    this.setLoginFlag(true)
                    this.setEmployee(employeeInfo.data);
                    this.checkOpenIdAndUpdate(this.ACCOUNT_TYPE_EMPLOYEE, this.employee)
                }

                this.goToHome();
            })();
        }
    },

    computed: {
        ...mapGetters(['loginState']),
        ...mapState(['openId', 'accountType', 'employee', 'visitor'])

    },

    methods: {
        ...mapMutations(['setLoginFlag', 'setAccountType', 'setVisitor', 'setEmployee']),

        checkOpenIdAndUpdate(accountType, account) {
            if (this.openId == null) {
                return
            }

            let updateObj = {
                "openId": this.openId
            }

            if ((this.ACCOUNT_TYPE_EMPLOYEE === accountType)&&(account.openId !== this.openId)) {
                (async () => {
                    let code = (await employeeUpdate(updateObj)).data.code
                    if (code === 200) {
                        this.printDebug("home update openid => ", updateObj)
                        this.employee.openId = this.openId
                    }
                })()
            } else if ((this.ACCOUNT_TYPE_VISITOR === accountType)&&(account.openId !== this.openId)) {
                (async () => {
                    let code = (await visitorModify(updateObj)).data.code
                    if (code === 200) {
                        this.printDebug("home update openid => ", updateObj)
                        this.visitor.openId = this.openId
                    }
                })()
            }
        },

        goToHome() {
            this.$router.push({ name: "home", params: {source: "accountsync"} });
        },

        goToAccountDetail() {
            this.$router.push({name: "accountdetail", params: {source: "accountsync"}});
        }
    }
}
</script>

<style lang="less" scoped>

.main {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

</style>
